<template>
  <div>
    <Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Categories</h1>
        <router-link :to="{ name: 'dashHome' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
    
      <div class="flex flex-wrap">
        <div class="flex__col1">
          <div class="list__item--body--sm">
            <form @submit.prevent>
              <input type="text" class="mb-3" placeholder="Label" v-model.trim="catForm.label" required />
              <div class="caption mb-1">Order:</div>
              <input type="number" v-model.trim="catForm.order" required />
              <div class="flex justify-flex-end mt-4 mr-5">
                <button class="btn btn__primary mb-5" @click="addCategory()">
                  Add Category
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex__col2">
          <div class="list__item" v-for="item in categories" :key="item.id">
            <div class="list__item--body">
              <input type="text" class="mb-3" placeholder="Label" v-model.trim="item.label" @change="updateCategory(item)" />
              <div class="caption mb-1">Order:</div>
              <input type="number" v-model.trim="item.order" @change="updateCategory(item)" />
              <div style="display: flex; justify-content: flex-end;">
                <button class="btn btn__small btn__dark mt-4 mr-5" @click="deleteCategory(item)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
const fb = require('../../../firebaseConfig.js')

export default {
  name: 'dashSettings',
  data() {
    return {
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
      catForm: {}
    }
  },
  created() {
    this.$store.dispatch("getCategories")
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'categories']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "Settings", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
  methods: {
    updateCategory(item){
      let cat = item
      fb.categoriesCollection.doc(cat.id).update(cat)
      this.$store.dispatch("getCategories")
    },
    addCategory() {
      let cat = this.catForm
      this.performingRequest = true
      fb.categoriesCollection.add(cat)
      .then(
        doc => {
          fb.categoriesCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
      setTimeout(() => {
        this.$store.dispatch("getCategories")
        this.performingRequest = false
        this.catForm = {}
      }, 1000)
    },
    deleteCategory(item) {
      fb.categoriesCollection.doc(item.id).delete()
      this.$store.dispatch("getCategories")
    },
  },
  components: {
    Loader,
    Breadcrumbs
  },
  beforeDestroy(){
    this.performingRequest = false
    this.performingRequest2 = false
    this.performingRequest3 = false
    this.catForm = {}
    this.$store.dispatch("clearCategories")
    delete this.performingRequest
    delete this.performingRequest2
    delete this.performingRequest3
  }
}
</script>


